import { tw } from '@/utils/tw';
import BaseArticlePaywallForm from 'base/components/standalone/ArticlePaywall/Form/theme';

const TextField = tw.theme({
  extend: BaseArticlePaywallForm.TextField,
  slots: {
    base: [
      'last:mb-3',
      'md:has-[input[name=firstName]]:flex-1',
      'md:has-[input[name=lastName]]:flex-1',
      'md:has-[input[name=email]]:min-w-full',
    ],
  },
  defaultVariants: {
    variant: 'secondary',
  },
});

const Button = tw.theme({
  extend: BaseArticlePaywallForm.Button,
  base: ['max-w-fit', 'md:block', 'md:mx-auto', 'mb-4', 'md:mb-5'],
  defaultVariants: {
    size: 'large',
    fill: true,
  },
});

const ArticlePaywallFormTheme = tw.theme({
  extend: BaseArticlePaywallForm,
  slots: {
    base: ['mb-3', 'sm:mb-4', 'md:px-13.5'],
    policy: ['text-content-sm', 'mb-6', '[&>a]:underline', '[&>a]:text-summer-red-700'],
    caption: [
      'text-content-sm',
      'max-w-58',
      'md:max-w-full',
      'mx-auto',
      'text-center',
      '[&>a]:underline',
      '[&>a]:text-summer-red-700',
      'mb-5',
    ],
    fieldset: ['flex', 'flex-col', 'md:flex-row', 'md:flex-wrap', 'gap-4', 'md:gap-x-6', 'md:gap-y-4'],
    provider: ['hidden', 'md:flex', 'text-content-sm', 'md:items-center', 'md:justify-center'],
  },
});

export default Object.assign(ArticlePaywallFormTheme, {
  TextField,
  Button,
});
