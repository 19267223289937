import { DialogTheme } from '@/components/Dialog/theme';
import { KlarnaPaymentTheme } from '@/components/KlarnaPayment/theme';
import { tw } from '@/utils/tw';
import BaseArticlePaywallTheme from 'base/components/standalone/ArticlePaywall/theme';

const ArticlePaywallFormTheme = tw.theme({
  extend: BaseArticlePaywallTheme,
  slots: {
    base: ['bg-linen-200', 'md:max-w-article', 'rounded'],
    description: ['text-content-sm'],
    headline: ['text-headline-lg', 'sm:text-headline-xl'],
    logo: ['h-11', 'w-11'],
    feature: ['text-content-sm'],
  },
});

export default Object.assign(ArticlePaywallFormTheme, {
  Dialog: DialogTheme,
  Form: BaseArticlePaywallTheme.Form,
  KlarnaPayment: KlarnaPaymentTheme,
  SuccessfulPayment: BaseArticlePaywallTheme.SuccessfulPayment,
});
