import { Icon } from '@/components/Icon';
import { Link } from '@/components/Link';
import FeminaPlusLogo from '@/femina/public/image/femina-plus-logo.svg';
import { StandaloneComponent } from '@/types/component';
import { ArticlePaywallProps, StandaloneArticlePaywall } from 'base/components/standalone/ArticlePaywall';
import KlarnaBadgeLogo from 'base/public/image/klarna-badge.png';

import NextImage from 'next/image';

export const FeminaStandaloneArticlePaywall: StandaloneComponent<ArticlePaywallProps> = ({ ...props }) => {
  return (
    <StandaloneArticlePaywall
      logo={<FeminaPlusLogo />}
      headline="Vill du läsa hela artikeln?"
      description="Starta din prenumeration av Femina Plus redan i dag – just nu bara 29 kr i månaden"
      features={[
        <>
          <Icon name="check" options={{ className: 'mt-0.5 flex-shrink-0' }} />
          Tillsvidareprenumeration utan bindningstid (säg upp enkelt när du vill)
        </>,
        <>
          <Icon name="check" options={{ className: 'mt-0.5 flex-shrink-0' }} />
          Läs alla artiklar på femina.se helt fritt utan begränsningar
        </>,
        <>
          <Icon name="check" options={{ className: 'mt-0.5 flex-shrink-0' }} />
          Tillgång till helt unikt innehåll och djupgående intervjuer, bara för dig som Plus-prenumerant
        </>,
      ]}
      successfulPayment={{
        headline: 'Tack för ditt köp',
        caption: 'och välkommen till Femina Plus',
        description: 'Det enda du behöver göra nu för att få tillgång till ditt unika innehåll är att:',
        steps: [
          'Gå till din mejl (samma som du angav i förra steget)',
          'Klicka på länken i mejlet för att bekräfta ditt konto',
          'Sen skickas du automatiskt tillbaka hit för att obegränsat ta del av allt innehåll som är unikt bara för dig som Plus-prenumerant.',
        ],
        contactInfo: (
          <>
            Om du har frågor eller behöver hjälp med din prenumeration, kontakta oss här på:{' '}
            <Link href="tel:0424443000" content="042 - 444 30 00" /> eller{' '}
            <Link href="mailto:femina@kundtjanst.se" content="femina@kundtjanst.se" />
          </>
        ),
      }}
      form={{
        button: 'Få obegränsad tillgång med Plus',
        caption: (
          <>
            Redan prenumerant? <br className="md:hidden" />
            <Link
              target="_blank"
              href="https://login.allermedia.se/?return=https%3A%2F%2Fwww.femina.se%2F"
              content="Logga in på ditt konto här"
            />
          </>
        ),
        policy: (
          <>
            Genom köp av Plus prenumeration kommer ett konto skapas till dig. Dina uppgifter hanteras tryggt enligt{' '}
            <Link
              content={'Aller Medias personuppgiftspolicy'}
              target="_blank"
              href="https://www.aller.se/integritetspolicy"
            />
            . Själva kontot kostar ingenting.
          </>
        ),
        provider: (
          <>
            Betala med
            <NextImage className="h-13 w-20" src={KlarnaBadgeLogo} alt="Klarna logo" />
          </>
        ),
      }}
      {...props}
    />
  );
};
